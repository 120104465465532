<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col v-if="item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.dormitorios"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Dormitório(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="(item.finalidade == 'Residencial' || item.finalidade == 'Residencial / Comercial') && item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.suites"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Suíte(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.banheiros"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Banheiro(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.vagas"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vaga(s)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">{{ items.tipo_imovel[0].areas.area ? items.tipo_imovel[0].areas.area : 'Área Ut / Cons / Terr' }} </span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area_total"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">{{ items.tipo_imovel[0].areas.area_total ? items.tipo_imovel[0].areas.area_total : 'Área Total' }}  </span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.tipo_imovel == 'Terreno'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.area_construida_terreno"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Área contruida no terreno</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="items.tipo_imovel[0].extra_area.includes('Frente')"  cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.frente"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Frente</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="items.tipo_imovel[0].extra_area.includes('Fundo')" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.fundo"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Fundo</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="items.tipo_imovel[0].extra_area.includes('Lado Direito')" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.lado_direito"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Lado direito</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="items.tipo_imovel[0].extra_area.includes('Lado Esquerdo')" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.lado_esquerdo"
                        type="number"
                        outlined
                        dense
                        suffix="m²"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Lado esquerdo</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.tipo_imovel != 'Area' && item.tipo_imovel != 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.imovel_ocupado"
                        :items="items.imovel_ocupado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Imovel ocupado?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.setor == 'Venda' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_venda"
                        outlined
                        dense
                        :rules="[rules.required, rules.min_value]"
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Venda</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_locacao"
                        outlined
                        dense
                        :rules="[rules.required, rules.min_value]"
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Locação</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col v-if="item.finalidade == 'Residencial' || item.finalidade == 'Residencial / Comercial'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_temporada"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Temporada</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col v-if="item.em_condominio == 'Sim' || (item.tipo_imovel == 'Apartamento' || item.tipo_imovel == 'Sala Comercial')" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_condominio"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Condomínio</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.isento_iptu"
                        :items="items.sim_nao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Isento de IPTU?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.isento_iptu != 'Sim'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_iptu"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl IPTU</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'"  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_seguro_fianca"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Seguro Fiança</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_seguro_incendio"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Seguro Incêndio</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_taxa"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Taxa</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col v-if="item.setor == 'Venda' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.aceita_permuta"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Aceita permuta?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.aceita_permuta == 'Sim' && (item.setor == 'Venda' || item.setor == 'Venda e Locação')" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.tipo_permuta"
                        :items="items.tipos_permuta"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipos de Permuta</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.aceita_permuta == 'Sim' && (item.setor == 'Venda' || item.setor == 'Venda e Locação')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.obs_permuta"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs permuta</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.setor == 'Venda' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.aceita_financiamento"
                        :items="items.sim_nao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Aceita financiamento?</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_avaliacao"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Vl Avaliaçāo</span>
                        </template>
                    </v-currency-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.topografia"
                        :items="items.topografia"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Topografia</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.documentacao"
                        :items="items.documentacao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Documentaçāo</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.regiao"
                        :items="items.regiao"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Regiāo</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <v-select
                        v-model="item.perto_de"
                        :items="items.perto_de"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Perto de:</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.tipo_imovel != 'Área' && item.tipo_imovel != 'Terreno'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.ano_construcao"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Ano da construçāo</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.estado_imovel == 'Em construçāo' || item.estado_imovel == 'Pré lançamento' || item.estado_imovel == 'Lançamento'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.data_entrega"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.data_entrega"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data entrega</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="data_entrega" @input="calendar.data_entrega = false"></v-date-picker>

                    </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.chaves"
                        :items="items.chaves"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Chaves</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.obs_chaves"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs chaves</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.observacoes"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Observações</span>
                        </template>
                    </v-textarea>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.estado_contrato_locacao"
                        :items="items.estado_contrato_locacao"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Contrato de locaçāo</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.vencimento_contrato_locacao"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            outlined
                            v-model="item.vencimento_contrato_locacao"
                            append-icon="mdi-calendar-range"
                            readonly
                            v-on="on"
                            :class="$css_field"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Vencimento do contrato</span>
                            </template>
                        </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="vencimento_contrato_locacao" @input="calendar.vencimento_contrato_locacao = false"></v-date-picker>

                    </v-menu>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.comissao_primeiro_aluguel"
                        type="number"
                        min="0"
                        max="100"
                        outlined
                        dense
                        suffix="%"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Comissāo Locaçāo</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="6" xs="6" sm="6" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.taxa_adm"
                        type="number"
                        min="0"
                        max="100"
                        outlined
                        dense
                        suffix="%"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Taxa ADM</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.administradora"
                        :items="items.administradora"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Administradora</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="item.setor == 'Locação' || item.setor == 'Venda e Locação'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        v-model="item.garantia_locacao"
                        :items="items.garantia_locacao"
                        outlined
                        dense
                        multiple
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Garantias aceitas</span>
                        </template>
                    </v-select>
                </v-col>

            </v-row>

            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                   <v-btn
                        dark
                        color="primary"
                        @click="validate()"
                        :class="$css_button"
                    >
                        Gravar
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </div>
</template>

<script>
import {OCUPACAO_IMOVEL_ITEMS, SIM_NAO_ITEMS, TIPO_PERMUTA_ITEMS, TOPOGRAFIA_ITEMS, DOCUMENTACAO_IMOVEL_ITEMS, REGIAO_IMOVEL_ITEMS, PERTO_DE_ITEMS, CHAVE_ITEMS, ESTADO_CONTRATO_LOCACAO_ITEMS, ADMINISTRADORA_ITEMS, GARANTIA_LOCACAO_ITEMS, TIPO_IMOVEL_PROPERTY_ITEMS} from '../config_properties/config_properties.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {  
            this.setObject() 
        },

        vencimento_contrato_locacao () {
            this.item.vencimento_contrato_locacao = this.formatDate(this.vencimento_contrato_locacao)
        },

        data_entrega () {
            this.item.data_entrega = this.formatDate(this.data_entrega)
        },
    },

    data () {
        return {
            item: {},

            items: {
                tipo_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                imovel_ocupado: OCUPACAO_IMOVEL_ITEMS,
                sim_nao: SIM_NAO_ITEMS,
                tipos_permuta: TIPO_PERMUTA_ITEMS,
                topografia: TOPOGRAFIA_ITEMS,
                documentacao: DOCUMENTACAO_IMOVEL_ITEMS,
                regiao: REGIAO_IMOVEL_ITEMS,
                perto_de: PERTO_DE_ITEMS,
                chaves: CHAVE_ITEMS,
                estado_contrato_locacao: ESTADO_CONTRATO_LOCACAO_ITEMS,
                administradora: ADMINISTRADORA_ITEMS,
                garantia_locacao: GARANTIA_LOCACAO_ITEMS
            },
  
            rules: {
                required: value => !!value || 'Obrigatório',
                min_value:  v => (v && v.length >= 5) || 'O valor precisa ser maior que zero (0)',
                min:  v => (v && v.length >= 8) || 'Mininmo de 8 caracteres',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            data_entrega: '',
            vencimento_contrato_locacao: '',

            calendar: {
                data_entrega: false,
                vencimento_contrato_locacao: false
            },
        }
    },

    methods: {
        setObject(){
            this.item = this.object 
            this.filterTipoImovel(this.item.tipo_imovel)
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.update(this.item)
            }
        },

        update(item){
            this.$store.dispatch('updateProperty', item)
                        .then(response => {
                            this.item = response.data   
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.item.id)
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

        filterTipoImovel(tipoImovel){
            this.items.tipo_imovel = this.items.tipo_imovel.filter(model => model.tipo == tipoImovel)
        },
    },
}
</script>